import { Field, useFormikContext } from "formik"
import React from "react"
import { isEmpty, omit } from "lodash"
import { StaticFieldDefinition } from "../../../types/form/sharedTypes"
import { CanopyCheckboxInput } from "@parachutehealth/canopy-checkbox-input"
import { CanopyTextareaField } from "@parachutehealth/canopy-textarea-field"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CanopyPickerField } from "@parachutehealth/canopy-picker-field"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { stringifyErrors } from "../../../utilities/form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import { CanopyCheckboxInputField } from "@parachutehealth/canopy-checkbox-input-field"

type DynamicFormFieldProps = {
  definition: StaticFieldDefinition
}

/**
 * The purpose of this component is for use in "simple" React forms; rather than have to directly invoke
 * each form element, a form can be defined with a simple array of objects (StaticFieldDefinition), and mapped
 * to this component (or fed to a FormSegment, which ultimately calls this).  It will create the appropriate Canopy
 * component and wire it up to your form.
 *
 * Assumptions/limitations:
 * 1. You are using Formik; relies on the `useFormik` hook.
 * 2. Your form contains only strings or arrays of strings. Yes, even if you're dealing with "numeric" IDs, everything
 *    sent via form request is ultimately a string anyway. This is partially driven by Canopy's internal representation of options.
 *    This also means that, like a traditional HTML form, choosing from a select or checkbox field, for instance, will only store
 *    the _value_ of the option, not the whole option itself.
 * 3. This does not allow hooking into onChange events. If you need really fine-grained access to the components, this might
 *    not be the approach for you.
 *
 * @example
 *    const myFields = [
 *        { type: "text", label: "Question 1", }
 *    ]
 */
const DynamicFormField = ({
  definition,
}: DynamicFormFieldProps): React.JSX.Element => {
  const {
    values,
    isSubmitting,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useFormikContext<string | string[]>()

  const { label, type = "text", required = false, ...other } = definition

  const forwardedValues = omit(other, ["initialValue"])
  const feedbackMessage = stringifyErrors(errors, definition.name)

  const canopyOptions: {
    label: string
    value: string
  }[] = React.useMemo(() => {
    return definition["options"] || []
  }, [definition])

  /**
   * Based on our current _values_ (e.g., IDs), determine which of the available
   * options are "selected". Canopy needs the entire option, not just the ID.
   */
  const selectedValues = (): { label: string; value: string }[] => {
    const value = values[definition.name]
    if (isEmpty(value)) return []

    return canopyOptions.filter((o) => {
      if (Array.isArray(value)) {
        return value.includes(o.value)
      } else {
        return value === o.value
      }
    })
  }

  switch (type) {
    case "select":
      // pluck out and store the _values_ of the selected options in Formik.
      const onChange = (newValue: null | OptionItem | OptionItem[]) => {
        if (Array.isArray(newValue)) {
          setFieldValue(
            definition.name,
            newValue.map((x) => x.value)
          )
        } else {
          setFieldValue(definition.name, newValue?.value || "")
        }
      }

      if (definition["multiple"]) {
        return (
          <CanopyComboboxField
            onBlur={handleBlur}
            onChange={onChange}
            options={canopyOptions}
            disabled={isSubmitting}
            value={selectedValues()}
            label={label}
            required={required}
            feedbackMessage={feedbackMessage}
            {...other}
          />
        )
      } else {
        return (
          <CanopyPickerField
            onChange={onChange}
            options={canopyOptions}
            label={label}
            value={selectedValues().at(0)}
            required={required}
            feedbackMessage={feedbackMessage}
            {...other}
          />
        )
      }
    case "checkbox":
      return (
        <CanopyCheckboxInputField
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          options={canopyOptions}
          required={required}
          label={label}
          value={values[definition.name]}
          feedbackMessage={feedbackMessage}
          {...forwardedValues}
        />
      )
    case "radio":
      return (
        <CanopyRadioInputField
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          options={canopyOptions}
          required={required}
          label={label}
          value={values[definition.name]}
          feedbackMessage={feedbackMessage}
          {...forwardedValues}
        />
      )
    case "switch":
      return (
        <Field
          as={CanopyCheckboxInput}
          disabled={isSubmitting}
          label={label}
          required={required}
          id={label}
          defaultChecked={values[definition.name]}
          {...other}
        />
      )
    case "textarea":
      return (
        <CanopyTextareaField
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          required={required}
          label={label}
          value={values[definition.name]}
          // @ts-ignore
          // Canopy's allowed input props are more strict, but not exported
          textareaProps={definition["textareaProps"]}
          feedbackMessage={feedbackMessage}
          {...forwardedValues}
        />
      )
    default:
      return (
        <CanopyTextInputField
          disabled={isSubmitting}
          onChange={handleChange}
          onBlur={handleBlur}
          id={label}
          size="small"
          required={required}
          label={label}
          value={values[definition.name]}
          // Canopy's allowed input props are more strict, but not exported
          // @ts-ignore
          inputProps={definition["inputProps"]}
          feedbackMessage={feedbackMessage}
          {...forwardedValues}
        />
      )
  }
}

export default DynamicFormField
