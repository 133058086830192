// @team @destroythefax
import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import * as styles from "./OrderActionsDropdown.module.scss"
import CancelButton from "applications/Workflow/containers/Navigation/components/CancelButton"
import { DmeOrder, Originator } from "sharedTypes"
import SnoozeButton from "applications/Workflow/containers/Navigation/components/SnoozeButton"
import UnsnoozeButton from "applications/Workflow/containers/Navigation/components/UnsnoozeButton"

interface Props {
  originator: Originator
  dmeOrder: DmeOrder
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
}

const OrderActionsDropdown: React.FC<Props> = ({
  originator,
  snoozeDmeOrder,
  unsnoozeDmeOrder,
  dmeOrder,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const icon = open ? "caret-up" : "caret-down"

  return (
    <div className="position-relative">
      <CanopyButton
        fullWidth
        variant="secondary"
        onClick={handleClick}
        iconEnd={icon}
      >
        Order&nbsp;actions
      </CanopyButton>
      {open && (
        <div className={styles.dropdownContainer}>
          {dmeOrder.snoozed ? (
            <UnsnoozeButton
              unsnoozeDmeOrder={unsnoozeDmeOrder}
              setOrderActionsDropdownOpen={setOpen}
            />
          ) : (
            <SnoozeButton
              snoozeDmeOrder={snoozeDmeOrder}
              dmeOrder={dmeOrder}
              setOrderActionsDropdownOpen={setOpen}
            />
          )}
          <CancelButton
            originator={originator}
            orderActionMenu
            setOrderActionsDropdownOpen={setOpen}
          />
        </div>
      )}
    </div>
  )
}

export default OrderActionsDropdown
