import { Box, Grid, IconButton, Paper } from "@material-ui/core"
import React from "react"
import { CanopyIcon } from "@parachutehealth/canopy-icon"
import { CanopyIconNames } from "@parachutehealth/canopy-icons/build/canopyIconGlyphNames"
import * as styles from "./index.module.scss"
import classNames from "classnames"

export const DEFAULT_SIDEBAR_WIDTH: string = "330px"

type SidebarLayoutProps = {
  sidebarTitle?: React.ReactNode /* shows at the top of the sidebar when open */
  sidebarActions?: React.ReactNode /* shows below sidebar title but above the "fold" */
  openContent: React.ReactNode[] /* shows within the sidebar when open; scrolls if needed */
  closedContent?: React.ReactNode /* shows within the sidebar when closed; suggested that only icons are used */
  mainContent: React.ReactNode /* populates the main content area (mui paper) */
  headerContent: React.ReactNode /* shows at the top of the page, above the sidebar and content area */
  sidebarWidth?: string
}

const SidebarLayout: React.FC<SidebarLayoutProps> = (
  props: SidebarLayoutProps
) => {
  const {
    openContent,
    closedContent,
    mainContent,
    sidebarTitle,
    headerContent,
    sidebarWidth = DEFAULT_SIDEBAR_WIDTH,
    sidebarActions,
  } = props
  const isSmallWindow = () => window.matchMedia("(max-width:1024px)")
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(
    !isSmallWindow().matches
  )

  const hideSidebar = (event: MediaQueryListEvent) => {
    setSidebarOpen(!event.matches)
  }

  React.useEffect(() => {
    const matchMedia: MediaQueryList = isSmallWindow()
    matchMedia.addEventListener("change", hideSidebar)
    return () => matchMedia.removeEventListener("change", hideSidebar)
  }, [])

  const ToggleButton = (): JSX.Element => {
    let iconName: CanopyIconNames, onClick: () => void, title: string
    if (sidebarOpen) {
      iconName = "angles-left"
      title = "Collapse the sidebar"
      onClick = () => setSidebarOpen(false)
    } else {
      iconName = "angles-right"
      title = "Expand the sidebar"
      onClick = () => setSidebarOpen(true)
    }

    return (
      <Box style={{ display: "flex", alignItems: "center" }}>
        <IconButton title={title} onClick={onClick}>
          <CanopyIcon name={iconName} size="medium" />
        </IconButton>
      </Box>
    )
  }

  const renderOpenContent = () => {
    if (!sidebarOpen) {
      return <></>
    } else {
      return (
        <>
          <Box
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "#f6f8f8",
            }}
          >
            <SidebarTitle />
            {sidebarOpen && <Box>{sidebarActions}</Box>}
          </Box>
          <Box className="canopy-p-1x">
            {openContent.map((content, index) => (
              <Box key={index} className="canopy-py-6x">
                {content}
              </Box>
            ))}
          </Box>
        </>
      )
    }
  }

  const renderClosedContent = () => {
    if (!sidebarOpen) {
      return (
        <>
          <SidebarTitle />
          {closedContent}
        </>
      )
    } else {
      return <></>
    }
  }

  const SidebarTitle = (): JSX.Element => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: sidebarOpen ? "space-between" : "center",
        }}
      >
        {sidebarOpen && (
          <div
            role="heading"
            className="canopy-typography-heading-large canopy-mbs-6x"
          >
            {sidebarTitle}
          </div>
        )}
        <ToggleButton />
      </div>
    )
  }

  return (
    <>
      <Box>{headerContent}</Box>
      <Grid spacing={2} container>
        <Grid
          role="complementary"
          item
          style={{ width: sidebarOpen ? sidebarWidth : "55px" }}
        >
          <Box>
            {renderOpenContent()}
            {renderClosedContent()}
          </Box>
        </Grid>
        <Grid item xs>
          <Paper className={classNames(styles.mainContainer)}>
            {mainContent}
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default SidebarLayout
