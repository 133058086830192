import React from "react"
import { PackageSku, SuggestionOption } from "../sharedTypes"
import { Form, Select } from "components/form"
import Suggestion from "components/form/Select/Suggestion"
import { omitOptionProps } from "components/form/Select"
import { convertToOption, meetsMinLength } from "./utils"
import SearchResult from "./SearchResult"

type Props = {
  formularyPriceEnabled: boolean
  hideIcon?: boolean
  placeholder?: string
  searchPackagesWithSku(query: string): Promise<{ data: PackageSku[] }>
  selectPackageWithSku(s: PackageSku): void
  isInHybridSearch?: boolean
}

const SkuQuickAdd: React.FunctionComponent<Props> = ({
  hideIcon,
  formularyPriceEnabled,
  placeholder = "Search",
  searchPackagesWithSku,
  selectPackageWithSku,
  isInHybridSearch = false,
}) => {
  const minLength = 3

  const searchable = (value: string): Promise<{ data: PackageSku[] }> => {
    if (meetsMinLength(value, minLength)) {
      return searchPackagesWithSku(value)
    } else {
      return new Promise((resolve) => resolve({ data: [] }))
    }
  }

  return (
    <div className="canopy-mbe-12x">
      <Form>
        <Select
          unwrapped={true}
          hasDropdownIndicator={!hideIcon}
          name="package-search-select"
          minLength={minLength}
          placeholder={placeholder}
          fetchOptions={(value) =>
            searchable(value).then((response) =>
              response.data.map(convertToOption)
            )
          }
          onChange={(_value, option) =>
            selectPackageWithSku(omitOptionProps(option))
          }
          isSearchable
          renderOption={(
            suggestion: SuggestionOption,
            { query, isFocused }: { query: string; isFocused: boolean }
          ) => {
            return (
              <SearchResult
                suggestion={suggestion}
                isHighlighted={isFocused}
                query={query}
                formularyPriceEnabled={formularyPriceEnabled}
                isInHybridSearch={isInHybridSearch}
              />
            )
          }}
          renderEmpty={(value) =>
            value && meetsMinLength(value, minLength) ? (
              <Suggestion>No matching products.</Suggestion>
            ) : (
              <Suggestion>
                Type a word with at least {minLength} characters to search
              </Suggestion>
            )
          }
          clearOnSelect
          blurInputOnChange
        />
      </Form>
    </div>
  )
}
export default SkuQuickAdd
