import React, { Dispatch, SetStateAction } from "react"
import ContinueButton from "applications/Workflow/components/ContinueButton"
import Pages from "../Pages"
import { DmeOrder, DmeOrderPermissions, Originator, Page } from "sharedTypes"
import * as styles from "./index.module.scss"
import OrderActionsDropdown from "applications/Workflow/containers/Navigation/components/OrderActionsDropdown/OrderActionsDropdown"

type Props = {
  pages: Page[]
  patientLink: React.ReactElement
  nextPagePath: string
  buttons: React.ReactElement[]
  originator: Originator
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  showSnoozeModal: boolean
  snoozeDmeOrder(params): Promise<void>
  unsnoozeDmeOrder(): Promise<void>
  dmeOrder: DmeOrder
  permissions: DmeOrderPermissions
}

function LeftNavigation({
  pages,
  patientLink,
  nextPagePath,
  buttons,
  unsnoozeDmeOrder,
  originator,
  snoozeDmeOrder,
  dmeOrder,
  permissions,
}: Props) {
  const activePage = pages.find((page) => page.active)
  const isPageChecked = activePage && activePage.checked

  return (
    <>
      <div className={styles.nav}>
        {patientLink}
        {permissions.snooze && (
          <div className={styles.orderActionsContainer}>
            <OrderActionsDropdown
              originator={originator}
              snoozeDmeOrder={snoozeDmeOrder}
              unsnoozeDmeOrder={unsnoozeDmeOrder}
              dmeOrder={dmeOrder}
            />
          </div>
        )}
        <Pages pages={pages} removeBottomBorder={true} />
      </div>
      <div className="canopy-mbe-8x canopy-mis-8x canopy-mie-8x">
        {nextPagePath && (
          <ContinueButton
            block
            isButtonOutlined={!isPageChecked}
            path={nextPagePath}
          />
        )}
      </div>
      {buttons.map((button, index) => (
        <div
          className="canopy-mbe-8x canopy-mis-8x canopy-mie-8x"
          key={`button-${button.props.className}-${index}`}
        >
          {button}
        </div>
      ))}
    </>
  )
}

export default LeftNavigation
