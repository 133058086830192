import React, { useState } from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyDialogModal } from "@parachutehealth/canopy-dialog-modal"
import { states } from "utilities/address"
import { CanopyPickerField } from "@parachutehealth/canopy-picker-field"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import * as styles from "./ServiceAreaStateModal.module.scss"

interface Props {
  cancel(): void
  onSave(state: string): void
  initialSelectedState: string
  defaultState: string
}

const ServiceAreaStateModal: React.FC<Props> = ({
  cancel,
  onSave,
  initialSelectedState,
  defaultState,
}) => {
  const [currentState, setCurrentState] = useState<string>(initialSelectedState)
  const stateOptions = states.map((state) => {
    return {
      label: state[1] === defaultState ? `${state[0]} (Default)` : state[0],
      value: state[1],
    }
  })

  return (
    <CanopyDialogModal
      size="medium"
      header="Service Area"
      open
      onClose={cancel}
      primaryFooterButton={
        <CanopyButton variant="primary" onClick={() => onSave(currentState)}>
          Save
        </CanopyButton>
      }
      secondaryFooterButton={
        <CanopyButton variant="secondary" onClick={cancel}>
          Cancel
        </CanopyButton>
      }
    >
      <div className={styles.modalBody}>
        <CanopyPickerField
          label="Service Area State"
          hiddenLabel
          defaultValue={currentState}
          options={stateOptions}
          onChange={(newValue) =>
            setCurrentState((newValue as OptionItem).value)
          }
        />
      </div>
    </CanopyDialogModal>
  )
}

export default ServiceAreaStateModal
