import { post, put } from "services/api"
import {
  patientUrl,
  setFollowersUrl,
  snoozeOrderUrl,
} from "applications/Workflow/urls"

export const updatePatient = (id, params) => {
  return put(`${patientUrl(id)}`, { patient: params })
}

export const updateFollowers = (params) => {
  return put(setFollowersUrl(), params)
}

export const snoozeOrder = (params) => {
  return post(snoozeOrderUrl(), params)
}

export const unsnoozeOrder = () => {
  return put(snoozeOrderUrl())
}
