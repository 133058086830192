import React from "react"
import {
  DmeOrder,
  Nullable,
  ConsignmentCloset,
  CatalogBrowseTab,
} from "sharedTypes"
import SidebarAwareContainer from "../SideBarAwareContainer"
import Filters from "./Filters"
import { CategoryWithSubcategories } from "../../sharedTypes"

interface Props {
  availableCategories: CategoryWithSubcategories[]
  categories: CategoryWithSubcategories[]
  combinedCatalogSearch: boolean
  canFilterByFormulary: boolean
  canFilterByYourOrganizationsSuppliers: boolean
  consignmentClosetOnly: boolean
  consignmentClosets: ConsignmentCloset[]
  currentCatalogBrowseTab: CatalogBrowseTab
  defaultConsignmentClosetId: string
  dmeOrder: DmeOrder
  formularyOnly: boolean
  selectCategory(n: number, isSubcategory?: boolean): void
  selectDefaultConsignmentCloset(id: string): Promise<void>
  selectedCategory: Nullable<number>
  selectState: (state: string) => void
  serviceAreaState: string
  toggleConsignmentClosetFilter(): void
  toggleFormularyFilter(): void
  toggleYourOrganizationsSuppliersFilter(): void
  yourOrganizationsSuppliersOnly: boolean
}

const ProductFilters: React.FC<Props> = ({
  availableCategories,
  categories,
  canFilterByFormulary,
  canFilterByYourOrganizationsSuppliers,
  combinedCatalogSearch,
  consignmentClosetOnly,
  consignmentClosets,
  currentCatalogBrowseTab,
  dmeOrder,
  defaultConsignmentClosetId,
  formularyOnly,
  selectDefaultConsignmentCloset,
  selectCategory,
  selectedCategory,
  selectState,
  serviceAreaState,
  toggleConsignmentClosetFilter,
  toggleFormularyFilter,
  toggleYourOrganizationsSuppliersFilter,
  yourOrganizationsSuppliersOnly,
}: Props) => {
  const { manageFulfillmentAgreements } = dmeOrder.permissions
  const { usesEnterpriseFeatures } = dmeOrder.clinicalFacility

  return (
    <>
      <SidebarAwareContainer role="filters">
        <Filters
          availableCategories={availableCategories}
          canFilterByFormulary={canFilterByFormulary}
          canFilterByYourOrganizationsSuppliers={
            canFilterByYourOrganizationsSuppliers
          }
          categories={categories}
          combinedCatalogSearch={combinedCatalogSearch}
          consignmentClosetOnly={consignmentClosetOnly}
          consignmentClosets={consignmentClosets}
          currentCatalogBrowseTab={currentCatalogBrowseTab}
          defaultConsignmentClosetId={defaultConsignmentClosetId}
          formularyOnly={formularyOnly}
          manageFulfillmentAgreements={manageFulfillmentAgreements}
          selectCategory={selectCategory}
          selectedCategory={selectedCategory}
          selectDefaultConsignmentCloset={selectDefaultConsignmentCloset}
          selectState={selectState}
          serviceAreaState={serviceAreaState}
          toggleConsignmentClosetFilter={toggleConsignmentClosetFilter}
          toggleYourOrganizationsSuppliersFilter={
            toggleYourOrganizationsSuppliersFilter
          }
          toggleFormularyFilter={toggleFormularyFilter}
          usesEnterpriseFeatures={usesEnterpriseFeatures}
          yourOrganizationsSuppliersOnly={yourOrganizationsSuppliersOnly}
        />
      </SidebarAwareContainer>
    </>
  )
}

export default ProductFilters
